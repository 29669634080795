<script setup lang="ts">
    import { useAuthStore } from '@/stores'
    import { storeToRefs } from 'pinia'

    const authStore = useAuthStore()
    const { currentUser } = storeToRefs(authStore)
</script>

<template>
    <v-menu>
        <template v-slot:activator="{ props }">
            <v-btn append-icon="mdi-account" v-bind="props">
                {{ currentUser?.vorname }} {{ currentUser?.nachname }}
            </v-btn>
        </template>

        <v-list>
            <v-list-item disabled>
                <v-list-item-title>
                    {{ currentUser?.email?.email }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                :to="{ name: 'requestPasswordChange' }"
                data-test="nav-user-request-password-change"
            >
                <v-list-item-title>Passwort ändern</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="{ name: 'faqView' }" data-test="nav-user-faq">
                <v-list-item-title>FAQ - Häufig gestellte Fragen</v-list-item-title>
            </v-list-item>
            <v-list-item
                v-if="authStore.isUserSwitched"
                @click="authStore.exitUserSwitch()"
                data-test="nav-user-end-impersonate"
            >
                <v-list-item-title>Nutzerwechsel beenden</v-list-item-title>
            </v-list-item>
            <v-list-item link>
                <v-list-item-title
                    data-test="nav-user-logout"
                    @click="async () => await authStore.logout()"
                    >Abmelden</v-list-item-title
                >
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<style scoped></style>
