<script setup lang="ts">
    import { useAlertStore, useAuthStore } from '@/stores'
    import { useSecurityService } from '@/services'
    import { storeToRefs } from 'pinia'
    import { useField, useForm, type FieldContext } from 'vee-validate'
    import { ref, onBeforeMount, watch } from 'vue'
    import router from '@/router'
    import { FetchError } from 'ofetch'
    import { extractHydraDescription, mapViolationsToFields } from '@/utils'

    const props = defineProps<{
        userId: string
        confirmationToken: string
    }>()

    type ChangePasswordForm = {
        password: string
        passwordRepeat: string
    }

    const alertStore = useAlertStore()
    const securityService = useSecurityService()
    const authStore = useAuthStore()
    const { currentUser, isAuthenticated } = storeToRefs(authStore)

    const showPassword = ref(false)
    const showPasswordRepeat = ref(false)
    const isLoading = ref(false)
    const isRequestSend = ref(false)
    const showSuccessMessage = ref(false)
    const globalError = ref('')
    const { handleSubmit } = useForm<ChangePasswordForm>()

    const fields: {
        [Property in keyof ChangePasswordForm]: FieldContext<ChangePasswordForm[Property]>
    } = {
        password: useField<string>('password', (value) => {
            if (!value) {
                return 'Passwort muss angegeben werden.'
            }

            if (value.length < 12) {
                return 'Passwort muss mind. 12 Zeichen haben'
            }

            return true
        }),
        passwordRepeat: useField<string>('passwordRepeat', (value) => {
            if (value !== fields.password.value.value) {
                return 'Passwörter müssen überein stimmen.'
            }
            return true
        })
    }

    const submit = handleSubmit(async (values) => {
        isLoading.value = true
        isRequestSend.value = true
        globalError.value = ''

        try {
            await securityService.changePassword({
                user: `/api/security-users/${props.userId}`,
                confirmationToken: props.confirmationToken,
                password: values.password
            })
            showSuccessMessage.value = true
        } catch (e: unknown) {
            if (e instanceof FetchError) {
                if (e.statusCode === 422) {
                    mapViolationsToFields(fields, e.data)
                } else {
                    globalError.value = extractHydraDescription(e.data)
                }
            }
            isRequestSend.value = false
            showSuccessMessage.value = false
        } finally {
            isLoading.value = false
        }
    })

    async function checkConfirmationToken() {
        try {
            await securityService.validateConfirmationToken({
                user: `/api/security-users/${props.userId}`,
                confirmationToken: props.confirmationToken
            })
        } catch (e: unknown) {
            alertStore.error(
                'Der von Ihnen genutzte Link ist nicht länger gültig. Schauen Sie nach, ob in Ihrem E-Mail-Postfach eine neuere E-Mail mit Link vorhanden ist oder beantragen Sie erneut ein neues Passwort.'
            )
            await router.push({ name: 'dashboard' })
        }
    }

    watch(currentUser, async (val) => {
        if (val?.userId !== props.userId) {
            await router.push({ name: 'dashboard' })
        }
    })

    onBeforeMount(async () => {
        await checkConfirmationToken()
    })
</script>

<template>
    <v-form @submit.prevent="submit" :disabled="showSuccessMessage">
        <v-card class="mx-auto" elevation="16" max-width="600">
            <v-card-item>
                <v-card-title>Neues Passwort eingeben</v-card-title>
            </v-card-item>

            <v-text-field
                class="ma-2"
                v-model="fields.password.value.value"
                label="Passwort"
                :type="showPassword ? 'text' : 'password'"
                name="password"
                required
                :error-messages="fields.password.errorMessage.value"
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="showPassword = !showPassword"
            />

            <v-text-field
                class="ma-2"
                v-model="fields.passwordRepeat.value.value"
                label="Passwort wiederholen"
                :type="showPasswordRepeat ? 'text' : 'password'"
                name="passwordRepeat"
                required
                :error-messages="fields.passwordRepeat.errorMessage.value"
                :append-inner-icon="showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="showPasswordRepeat = !showPasswordRepeat"
            />

            <v-card-actions>
                <v-btn
                    type="submit"
                    color="#00527e"
                    variant="flat"
                    block
                    :disabled="isRequestSend"
                    data-test="btn-change-password"
                    :loading="isLoading"
                >
                    Passwortänderung bestätigen</v-btn
                >
            </v-card-actions>
        </v-card>

        <v-alert
            v-if="showSuccessMessage"
            class="mx-auto mt-8"
            elevation="16"
            max-width="600"
            type="success"
            title="Herzlichen Glückwunsch!"
            text="Ihr Passwort wurde erfolgreich geändert."
        />
        <v-container class="d-flex justify-center">
            <v-btn
                v-if="showSuccessMessage && !isAuthenticated"
                size="x-small"
                color="#00527e"
                variant="text"
                :to="{ name: 'login' }"
                >Zurück zur Anmeldung</v-btn
            >
        </v-container>
        <v-alert
            v-if="globalError"
            class="mx-auto mt-8"
            elevation="16"
            max-width="600"
            type="error"
            :text="globalError"
        />
    </v-form>
</template>

<style scoped></style>
