<script setup lang="ts">
    import { type AendereBemerkungenHauptstelleRequest, type Fachbetrieb } from '@/js/model'
    import { computed, onMounted, ref } from 'vue'
    import { truncateLines } from '@/utils'
    import { useAlertStore, useFachbetriebStore } from '@/stores'

    const props = withDefaults(
        defineProps<{
            fachbetrieb: Fachbetrieb
        }>(),
        {}
    )

    const emit = defineEmits<{
        itemChanged: [event: Fachbetrieb]
    }>()

    const alertStore = useAlertStore()
    const fachbetriebStore = useFachbetriebStore()
    const dialog = ref<boolean>(false)
    const bemerkung = ref<string>('')

    function setInitialValues() {
        bemerkung.value = props.fachbetrieb.bemerkungenHauptstelle
    }

    onMounted(async () => {
        setInitialValues()
    })

    const handleBemerkungSubmit = async () => {
        if (bemerkung.value) {
            const request: AendereBemerkungenHauptstelleRequest = {
                fachbetrieb: props.fachbetrieb['@id'],
                bemerkung: bemerkung.value
            }

            await fachbetriebStore.aendereBemerkungenHauptstelle(request)
            const result = fachbetriebStore.getFachbetriebByIri(String(props.fachbetrieb['@id']))
            if (result) {
                alertStore.success(
                    `Bemerkungen der Hauptstelle am Fachbetrieb "${result.mitgliedsnummer}" erfolgreich geändert.`
                )
                emit('itemChanged', result)
                dialog.value = false
            }
        }
    }

    const handleAbort = async () => {
        setInitialValues()
        dialog.value = false
    }

    const maxLength = 200
    const maxLineLength = 50
    const shortBemerkung = computed(() => {
        if (props.fachbetrieb.bemerkungenHauptstelle.length > maxLength) {
            return truncateLines(
                props.fachbetrieb.bemerkungenHauptstelle
                    .replace(/ /g, '\u{00A0}') // replace space with no break space
                    .substring(0, maxLength - 1)
                    .concat('…'),
                maxLineLength
            )
        }
        if (props.fachbetrieb.bemerkungenHauptstelle.length == 0) {
            return '–'
        }
        return props.fachbetrieb.bemerkungenHauptstelle.replace(/\r?\n|\r/g, ' ')
    })

    const isLoading = computed(() => {
        return fachbetriebStore.isMethodLoading('aendereBemerkungenHauptstelle')
    })

    const isSubmitDisabled = computed((): boolean => {
        return (
            props.fachbetrieb.bemerkungenHauptstelle === bemerkung.value ||
            bemerkung.value.length > 2000
        )
    })
</script>

<template>
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ props: activatorProps }">
            <span class="nl2br" :title="bemerkung">
                {{ shortBemerkung }}
            </span>
            <v-btn
                v-bind="activatorProps"
                append-icon="mdi-pencil"
                data-test="bemerkung-hauptstelle-cell"
                variant="text"
                :title="bemerkung"
            >
            </v-btn>
        </template>
        <v-card prepend-icon="mdi-pencil" title="Fachbetrieb: Bemerkung der ÜWG ändern">
            <v-card-text>
                <v-row dense>
                    <v-col cols="12">
                        <v-textarea
                            v-model="bemerkung"
                            label="Bemerkung"
                            rows="8"
                            no-resize
                            variant="solo-filled"
                            data-test="textarea-bemerkung"
                            :counter="2000"
                            :rules="[
                                (v) =>
                                    (v || '').length <= 2000 ||
                                    'Die Bemerkung darf höchstens 2000 Zeichen lang sein.'
                            ]"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-alert type="info" color="grey" icon="mdi-eye">
                            Diese Bemerkung ist nur für ÜWG-Mitarbeiter sichtbar.
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text="Abbrechen" variant="plain" @click="handleAbort"></v-btn>

                <v-btn
                    color="primary"
                    text="Speichern"
                    variant="tonal"
                    @click="handleBemerkungSubmit"
                    :disabled="isSubmitDisabled"
                    :loading="isLoading"
                />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
