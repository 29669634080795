<script setup lang="ts">
    import dayjs from 'dayjs'
    import {
        type Betriebspruefung,
        type Fachbetrieb,
        type FuegeBetriebspruefungsdokumentHinzuRequest
    } from '@/js/model'
    import { useFachbetriebService } from '@/services'
    import { useAlertStore, useBetriebspruefungStore } from '@/stores'
    import { computed, onMounted, ref } from 'vue'
    import { formatDate } from '@/utils'
    import { FilesCell } from '@/components'

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
    }>()

    const emit = defineEmits<{
        itemChanged: [event: Betriebspruefung]
    }>()

    type SubCategory = { content: string }
    type MainCategory = { content: string; subs?: SubCategory[] }

    const fachbetriebService = useFachbetriebService()
    const alertStore = useAlertStore()
    const betriebspruefungStore = useBetriebspruefungStore()

    const fileDate = ref<Date | null>(null)
    const isLoading = ref<boolean>(false)
    const showDialog = ref<boolean>(false)
    const mainCategory = ref<MainCategory | null>(null)
    const subCategory = ref<SubCategory | null>(null)
    const additionalFilenamePart = ref<string>('')

    const files = ref<File[]>([])

    const mainCategoryItems = ref<MainCategory[]>([
        { content: 'Prüfbericht' },
        {
            content: 'Unterlagen Betriebsbeauftragter',
            subs: [
                { content: 'Nachweis Teilnahme FBS / Sachkundennachweis' },
                { content: 'Nachweis Qualifikation' }
            ]
        },
        { content: 'Fachbetrieb' },
        {
            content: 'Fachliche Qualifikation',
            subs: [
                { content: 'SV-Prüfbericht' },
                { content: 'Referenzanlage' },
                { content: 'Fachgespräch' }
            ]
        },
        { content: 'Sonstiges' }
    ])

    const fachbetrieb = ref<Fachbetrieb | null>(null)

    const isSubmitDisabled = computed(() => {
        return isLoading.value === true || files.value.length === 0
    })

    onMounted(async () => {
        fileDate.value = new Date()
        fachbetrieb.value = await fachbetriebService.getFachbetriebById(
            props.betriebspruefung.fachbetriebId
        )
    })

    function setInitialValues() {
        files.value = []
        additionalFilenamePart.value = ''
        isLoading.value = false
    }

    onMounted(async () => {
        setInitialValues()
    })

    const onOrderChanged = (newFiles: File[]) => {
        files.value = [...newFiles]
    }

    const additionalFilenamePartItems = computed(() => {
        if ('Unterlagen Betriebsbeauftragter' === mainCategory.value?.content) {
            return ['Nachweis Teilnahme Wiederholungsschulung']
        }
        if ('Fachbetrieb' === mainCategory.value?.content) {
            return [
                'Nachweis Werkzeug',
                'Nachweis MA-Schulung',
                'Nachweis Regelwerk',
                'Nachweis Unterweisung',
                'Nachweis Entsorgung'
            ]
        }

        return []
    })

    const dokumentTyp = computed<string>(() => {
        const mainCategoryItem = mainCategoryItems.value.find(
            (category) => category.content === mainCategory.value?.content
        )

        if (!mainCategoryItem) {
            return '' // Wenn keine Übereinstimmung gefunden wird, zurückgeben.
        }

        if (subCategory?.value) {
            const subCategoryItem = mainCategoryItem.subs?.find(
                (sub) => sub.content === subCategory.value?.content
            )
            return subCategoryItem?.content || mainCategoryItem.content
        }

        return mainCategoryItem.content
    })

    const readFile = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()

            reader.onload = (res) => {
                resolve(res.target?.result as string)
            }
            reader.onerror = (err) => reject(err)

            reader.readAsDataURL(file)
        })
    }

    const readFiles = async (): Promise<string[]> => {
        const promises: Promise<string>[] = []
        files.value.forEach((file) => {
            promises.push(readFile(file))
        })
        return await Promise.all(promises)
    }

    const handleFormSubmit = async () => {
        if (!dokumentTyp.value) {
            const message = 'Der Dateityp muss vor dem Speichern festgelegt werden.'
            alertStore.warning(message)

            return
        }

        const request: FuegeBetriebspruefungsdokumentHinzuRequest = {
            betriebspruefung: props.betriebspruefung['@id'],
            fileDataList: await readFiles(),
            dokumentTyp: { typ: dokumentTyp.value },
            additionalFilenamePart: additionalFilenamePart.value,
            dokumentDate: dayjs(fileDate.value).toISOString()
        }
        isLoading.value = true
        try {
            const result = await betriebspruefungStore.fuegeBetriebspruefungsdokumentHinzu(request)
            alertStore.success('Dokument erfolgreich hinzugefügt.')
            emit('itemChanged', result)
            showDialog.value = false
        } catch (error) {
            const message = 'Beim Speichern des Prüfungsergebnisses ist etwas schiefgelaufen.'
            alertStore.error(message)
            throw new Error(message)
        } finally {
            setInitialValues()
        }
    }

    const handleAbort = async () => {
        showDialog.value = false
        setInitialValues()
    }
</script>

<template>
    <v-dialog v-model="showDialog">
        <template v-slot:activator="{ props: activatorProps }">
            <v-card-actions>
                <v-btn
                    prepend-icon="mdi-clipboard-file-outline"
                    block
                    variant="tonal"
                    color="primary"
                    size="small"
                    :text="`${betriebspruefung.dokumentIds.length ? 'weiteres ' : ''}Prüfungsdokument hochladen`"
                    v-bind="activatorProps"
                />
            </v-card-actions>
        </template>
        <v-card
            prepend-icon="mdi-clipboard-file-outline"
            title="Dokument zur Betriebsprüfung hochladen"
        >
            <v-divider></v-divider>
            <v-card-title>1. Kategorisierung des Dokuments bestimmen</v-card-title>
            <v-card-subtitle>Dateityp wählen</v-card-subtitle>
            <v-card-text class="">
                <v-row>
                    <v-col cols="12" class="pt-0">
                        <v-btn-toggle
                            v-model="mainCategory"
                            color="primary"
                            group
                            density="compact"
                            variant="outlined"
                            class="overflow-x-auto"
                            :disabled="isLoading"
                        >
                            <v-btn
                                v-for="mainCategoryItem in mainCategoryItems"
                                :value="mainCategoryItem"
                                :key="mainCategoryItem.content"
                                size="small"
                                >{{ mainCategoryItem.content }}
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col v-if="mainCategory?.subs?.length" cols="12" class="pt-0">
                        <v-card-subtitle>Untertyp spezifizieren (optional)</v-card-subtitle>
                        <v-btn-toggle
                            v-model="subCategory"
                            color="primary"
                            group
                            variant="outlined"
                            density="compact"
                            :disabled="isLoading"
                        >
                            <v-btn
                                v-for="categoryItem in mainCategory.subs"
                                :value="categoryItem"
                                :key="categoryItem.content"
                                size="small"
                                >{{ categoryItem.content }}
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-subtitle>Datum der Datei</v-card-subtitle>
            <v-card-text class="pt-0">
                <VueDatePicker
                    v-if="fileDate"
                    v-model="fileDate"
                    :week-numbers="{ type: 'iso' }"
                    placeholder="Datum der Datei (wird für Dateinamen verwendet)"
                    :clearable="false"
                    required
                    time-picker-inline
                    locale="de"
                    :enable-time-picker="false"
                    format="dd.LL.y"
                    cancel-text="abbrechen"
                    select-text="auswählen"
                    :teleport="true"
                    six-weeks="center"
                    :disabled="isLoading"
                    :action-row="{ showPreview: false }"
                />
            </v-card-text>
            <v-card-subtitle>Eigenen Dokumentnamensteil vergeben</v-card-subtitle>
            <v-card-text class="pt-0 pb-0">
                <v-combobox
                    v-model="additionalFilenamePart"
                    :items="additionalFilenamePartItems"
                    type="text"
                    density="compact"
                    placeholder="zusätzlicher Teil des Dokumentnamens"
                    label="Dokumentnamensteil (optional)"
                    name="additionalFilenamePart"
                    help="Wird im Dateinamen vor dem Datum eingefügt."
                    clearable
                    variant="outlined"
                    :disabled="isLoading"
                    autocomplete="off"
                    persistent-clear
                />
            </v-card-text>
            <v-card-subtitle>Dateiname unter dem das Dokument gespeichert wird</v-card-subtitle>
            <v-card-text v-if="fachbetrieb" class="pt-0">
                <pre class="pa-2 bg-grey font-weight-bold overflow-x-auto"
                    >{{ fachbetrieb.firma.nameOne }},{{ fachbetrieb.firma.nameTwo }},{{
                        fachbetrieb.mitgliedsnummer
                    }}{{ dokumentTyp ? '_' + dokumentTyp : ''
                    }}{{ additionalFilenamePart ? '_' + additionalFilenamePart : '' }}_{{
                        formatDate(fileDate)
                    }}{{ '.pdf' }}</pre
                >
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title>2. Bilder/PDFs hochladen</v-card-title>
            <v-card-text class="pt-0">
                <v-row>
                    <v-col>
                        <v-file-input
                            v-model="files"
                            accept="image/*,.pdf"
                            label="Bilder/PDFs"
                            counter
                            multiple
                            show-size
                            hint="Bilder und PDFs werden nach dem Hochladen zu einer PDF-Datei zusammengefügt."
                            persistent-hint
                            placeholder="Bilder/PDFs hochladen"
                            prepend-icon="mdi-camera"
                            :disabled="isLoading"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider v-if="files.length"></v-divider>
            <v-card-text v-if="files.length">
                <files-cell
                    :files="files"
                    @order-changed="onOrderChanged"
                    :disabled="!!isLoading"
                ></files-cell>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    text="Abbrechen"
                    variant="plain"
                    @click="handleAbort"
                    :disabled="isLoading"
                />

                <v-btn
                    color="primary"
                    text="Speichern"
                    variant="tonal"
                    :loading="isLoading"
                    @click="handleFormSubmit"
                    :disabled="isSubmitDisabled"
                />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
