<script setup lang="ts">
    import {
        type AendereBemerkungDerBetriebspruefungRequest,
        type Betriebspruefung
    } from '@/js/model'
    import { useBetriebspruefungService } from '@/services'
    import { computed, onMounted, ref } from 'vue'

    const props = withDefaults(
        defineProps<{
            betriebspruefung: Betriebspruefung
            noTitle?: boolean
        }>(),
        {
            noTitle: false
        }
    )

    const emit = defineEmits<{
        itemChanged: [event: Betriebspruefung]
    }>()

    const service = useBetriebspruefungService()

    const dialog = ref<boolean>(false)
    const bemerkung = ref<string>('')

    function setInitialValues() {
        bemerkung.value = props.betriebspruefung.bemerkung
    }

    onMounted(async () => {
        setInitialValues()
    })

    const handleBemerkungSubmit = async () => {
        if (bemerkung.value) {
            const request: AendereBemerkungDerBetriebspruefungRequest = {
                betriebspruefung: props.betriebspruefung['@id'],
                bemerkung: bemerkung.value
            }

            const result = await service.aendereBemerkungDerBetriebspruefung(request)

            if (result) {
                emit('itemChanged', result)
                dialog.value = false
            }
        }
    }

    const handleAbort = async () => {
        setInitialValues()
        dialog.value = false
    }

    const maxLength = 50
    const shortBemerkung = computed(() => {
        if (props.betriebspruefung.bemerkung.length > maxLength) {
            return props.betriebspruefung.bemerkung
                .replace(/\r?\n|\r/g, ' ')
                .substring(0, maxLength - 1)
                .concat('…')
        }
        if (props.betriebspruefung.bemerkung.length == 0) {
            return '–'
        }
        return props.betriebspruefung.bemerkung.replace(/\r?\n|\r/g, ' ')
    })
    const isSubmitDisabled = computed((): boolean => {
        return props.betriebspruefung.bemerkung === bemerkung.value || bemerkung.value.length > 2000
    })
</script>

<template>
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                v-bind="activatorProps"
                :append-icon="noTitle ? '' : 'mdi-pencil'"
                data-test="bemerkung-cell"
                variant="text"
                class="text-none"
                :title="bemerkung"
            >
                <v-icon v-if="noTitle">mdi-pencil</v-icon>
                <template v-else>{{ shortBemerkung }}</template>
            </v-btn>
        </template>
        <v-card prepend-icon="mdi-pencil" title="Bemerkung der Betriebsprüfung ändern">
            <v-card-text>
                <v-row dense>
                    <v-col cols="12">
                        <v-textarea
                            v-model="bemerkung"
                            label="Bemerkung"
                            rows="8"
                            no-resize
                            variant="solo-filled"
                            data-test="textarea-bemerkung"
                            :counter="2000"
                            :rules="[
                                (v) =>
                                    (v || '').length <= 2000 ||
                                    'Die Bemerkung darf höchstens 2000 Zeichen lang sein.'
                            ]"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" sm="6">
                        <v-alert type="info" color="grey" icon="mdi-eye">
                            Die Bemerkung zur Betriebsprüfung ist nur für Sie als Fachprüfer
                            einsehbar.
                        </v-alert>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-alert type="info" color="grey" class="">
                            Die Bemerkung wird automatisch für die nächste Betriebsprüfung
                            übernommen.
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text="Abbrechen" variant="plain" @click="handleAbort"></v-btn>

                <v-btn
                    color="primary"
                    text="Speichern"
                    variant="tonal"
                    @click="handleBemerkungSubmit"
                    :disabled="isSubmitDisabled"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
