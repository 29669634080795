import { useFetchItems } from '@/composables'
import type {
    AendereBemerkungAnDieLandesstelleRequest,
    AendereInterneBemerkungRequest,
    MarkiereBeurkundungAlsVersendetRequest,
    MarkiereRechnungAlsVersendetRequest,
    NehmeOrdnungspruefungVorRequest,
    Zertifizierung,
    ZertifizierungenResponse
} from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useZertifizierungService = () => {
    const getAllZertifizierungen = async (fetchParams: FetchParams = {}) => {
        const fetchItems = useFetchItems({ baseURL: '/api/zertifizierungen' })

        return await fetchItems.get<ZertifizierungenResponse>({ fetchParams })
    }

    const getZertifizierungById = (id: string) => {
        return getZertifizierungByIri(`/api/zertifizierungen/${id}`)
    }

    const getZertifizierungByIri = (iri: Zertifizierung['@id']) => {
        return useFetch({ baseURL: iri ?? '' }).get<Zertifizierung>({})
    }

    const markiereRechnungAlsVersendet = async (request: MarkiereRechnungAlsVersendetRequest) => {
        return await useFetch({
            baseURL: '/api/zertifizierungen/markiere-rechnung-als-versendet'
        }).post<Zertifizierung>({ body: request })
    }

    const markiereBeurkundungAlsVersendet = async (
        request: MarkiereBeurkundungAlsVersendetRequest
    ) => {
        return await useFetch({
            baseURL: '/api/zertifizierungen/markiere-beurkundung-als-versendet'
        }).post<Zertifizierung>({ body: request })
    }

    const nehmeOrdnungspruefungVor = async (request: NehmeOrdnungspruefungVorRequest) => {
        return await useFetch({
            baseURL: '/api/zertifizierungen/nehme-ordnungspruefung-vor'
        }).post<Zertifizierung>({ body: request })
    }

    const aendereBemerkungAnDieLandesstelle = async (
        request: AendereBemerkungAnDieLandesstelleRequest
    ) => {
        return await useFetch({
            baseURL: '/api/zertifizierungen/aendere-bemerkung-an-die-landesstelle'
        }).post<Zertifizierung>({ body: request })
    }

    const aendereInterneBemerkung = async (request: AendereInterneBemerkungRequest) => {
        return await useFetch({
            baseURL: '/api/zertifizierungen/aendere-interne-bemerkung'
        }).post<Zertifizierung>({ body: request })
    }

    return {
        getZertifizierungById,
        getAllZertifizierungen,
        markiereBeurkundungAlsVersendet,
        markiereRechnungAlsVersendet,
        nehmeOrdnungspruefungVor,
        aendereBemerkungAnDieLandesstelle,
        aendereInterneBemerkung
    }
}
