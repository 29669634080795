export const truncateLines = (multilineText: string, maxLineLength: number): string => {
    // Split the multilineText string into individual lines
    const lines = multilineText.split(/\r?\n/)

    // Process each line
    const processedLines = lines.map((line) => {
        if (line.length > maxLineLength) {
            return line.slice(0, maxLineLength - 1) + '…'
        }
        return line
    })

    return processedLines.join('\n')
}
