<script lang="ts" setup>
    import { ref } from 'vue'
    import {
        VBtn,
        VContainer,
        VExpansionPanel,
        VExpansionPanels,
        VExpansionPanelText,
        VExpansionPanelTitle,
        VImg
    } from 'vuetify/components'

    const faqs = ref([
        {
            title: 'PDF-Vorschau im Firefox aktivieren // Firefox lädt scheinbar wahllos PDFs herunter',
            content: `Standardmäßig lädt Firefox PDF-Dateien herunter, anstatt sie anzuzeigen.
    Um die Vorschau zu aktivieren, gehen Sie folgendermaßen vor:
    <ul class="pl-5">
      <li>Öffnen Sie die Firefox-Einstellungen.</li>
      <li>Geben Sie dazu in die Adressleiste <a href='about:preferences' target='_blank'>about:preferences</a> ein und drücken Sie Enter.</li>
      <li>Scrollen Sie zum Abschnitt <em>Dateien und Anwendungen</em> → <em>Anwendungen</em></li>
      <li>Suchen Sie den Eintrag für "PDF" und ändern Sie die Aktion auf "In Firefox öffnen".</li>
      <li>Anbei zwei Bilder für vorher und nachher:</li>
    </ul>`,
            images: [
                {
                    src: new URL('../images/FAQ_Firefox_PDF_vorher.png', import.meta.url).href,
                    alt: 'Vorher: PDF wird heruntergeladen',
                    overlay: false
                },
                {
                    src: new URL('../images/FAQ_Firefox_PDF_nachher.png', import.meta.url).href,
                    alt: 'Nachher: PDF wird in Firefox angezeigt',
                    overlay: false
                }
            ]
        },
        {
            title: 'Warum zeigt Firefox in den Tabellen keine Bildlaufleiste (Scrollbar) an? // Ich kann die unteren Tabelleneinträge nicht sehen.',
            content: `Im Firefox-Browser werden Bildlaufleisten (Scrollbars) nur während des Bildlaufs (Scrollens)
    angezeigt. Man kann dieses Verhalten ändern und die Bildlaufleisten dauerhaft anzeigen:
    <ul class="pl-5">
        <li>Öffnen Sie die Firefox-Einstellungen</li>
        <li>Geben Sie dazu in die Adressleiste <a href='about:preferences' target='_blank'>about:preferences</a> ein und drücken Sie Enter.</li>
        <li>Scrollen Sie zum Abschnitt <em>Surfen</em></li>
        <li>Setzen Sie das Häkchen vor <em>Bildlaufleisten immer anzeigen</em></li>
    </ul>`,
            images: [
                {
                    src: new URL('../images/FAQ_Firefox_Scrollbar.png', import.meta.url).href,
                    alt: 'Einstellungen: Bildlaufleisten immer anzeigen',
                    overlay: false
                }
            ]
        },
        {
            title: 'Was tun, wenn ein Fachbetrieb kündigen möchte?',
            content: `Die Kündigung eines Fachbetriebs wird ausschließlich von der ÜWG bearbeitet.
    <ul class="pl-5">
      <li>Fachprüfer müssen sich darum nicht kümmern.</li>
      <li>Die Kündigung muss direkt an die ÜWG weitergeleitet werden.</li>
      <li>Landesstellen-Nutzer können dies unter "Bemerkung zum Fachbetrieb" dokumentieren.</li>
      <li>Fachprüfer können eine Notiz unter "Bemerkungen des Fachprüfers für die Landesstelle" hinterlegen, wenn sie eine Betriebsprüfung abschließen.</li>
    </ul>`
        },
        {
            title: 'Was tun, wenn ein Fachbetrieb nicht geprüft werden möchte?',
            content: `Falls ein Fachbetrieb keine Prüfung wünscht, gehen Sie wie folgt vor:
    <ul class="pl-5">
      <li>Senden Sie eine E-Mail mit den Informationen an die ÜWG.</li>
      <li>Alternativ können Sie eine Notiz unter "Bemerkungen des Fachprüfers für die Landesstelle" hinterlegen, wenn Sie eine Betriebsprüfung abschließen.</li>
    </ul>`
        },
        {
            title: 'Wie lange kann ich einen Dokumenten-Download-Link verwenden?',
            content: `
    <ul class="pl-5">
      <li>Der Link zum Herunterladen eines Dokuments ist 60 Minuten lang aktiv.</li>
      <li>Nach Ablauf dieser Zeit funktioniert der Link nicht mehr.</li>
      <li>Wenn Sie den Link erneut in Vera aufrufen, wird er jedes Mal neu erstellt und hat dann wieder eine Gültigkeit von 60 Minuten.</li>
    </ul>`
        },
        {
            title: 'Meine Frage ist hier nicht beantwortet. An wen kann ich mich wenden?',
            content: `Wenn Ihre Frage nicht beantwortet wurde, dann schreiben Sie bitte eine E-Mail an <a href="mailto:info@uewg-shk.de">info@uewg-shk.de</a>.`
        }
    ])
</script>

<template>
    <v-container>
        <h1>FAQ - Häufig gestellte Fragen</h1>
        <v-expansion-panels>
            <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
                <v-expansion-panel-title>{{ faq.title }}</v-expansion-panel-title>
                <v-expansion-panel-text>
                    <div v-html="faq.content"></div>
                    <div
                        v-if="faq.images"
                        class="mt-3 d-flex flex-row align-items-start justify-start"
                    >
                        <template v-for="(image, imgIndex) in faq.images" :key="imgIndex">
                            <v-img
                                :src="image.src"
                                :alt="image.alt"
                                class="mb-2 mr-2"
                                max-height="500"
                                contain
                                @click="image.overlay = !image.overlay"
                            />
                            <v-overlay
                                v-model="image.overlay"
                                content-class="d-flex align-center justify-center w-100 h-100"
                                max-height="95%"
                            >
                                <v-card max-width="95%" max-height="95%">
                                    <v-card-title>{{ image.alt }}</v-card-title>
                                    <v-card-text class="d-flex justify-center pt-4 border">
                                        <vue-image-zoomer
                                            hover-message="Drüberfahren um zu zoomen"
                                            :title="image.alt"
                                            :regular="image.src"
                                            img-class="border w-100"
                                        />
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                            variant="tonal"
                                            color="primary"
                                            block
                                            @click="image.overlay = false"
                                            >Schließen</v-btn
                                        >
                                    </v-card-actions>
                                </v-card>
                            </v-overlay>
                        </template>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>
