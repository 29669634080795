import { defineStore } from 'pinia'
import { ref } from 'vue'
import type {
    AendereBemerkungenHauptstelleRequest,
    AendereBemerkungenLandesstelleRequest,
    Fachbetrieb
} from '@/js/model'
import { useFachbetriebService } from '@/services'
import { useLoading } from '@/composables/useLoading'

export const useFachbetriebStore = defineStore('fachbetrieb', () => {
    const fachbetriebe = ref<Fachbetrieb[]>([])
    const anzahlFachbetriebeOfFachpruefer = ref<{ [key: string]: number | undefined }>({})
    const service = useFachbetriebService()
    const { isLoading, isMethodLoading, loadingMethods, setLoading } = useLoading()

    const fetchFachbetriebe = async () => {
        await setLoading('fetchFachbetriebe', async () => {
            const result = await service.getAllFachbetriebe()
            fachbetriebe.value = result['hydra:member']
        })
    }

    const fetchAnzahlFachbetriebeByFachprueferId = async (fachprueferId: string) => {
        await setLoading('fetchAnzahlFachbetriebeByFachprueferId', async () => {
            const result = await service.getAnzahlFachbetriebeByFachprueferId(fachprueferId)
            anzahlFachbetriebeOfFachpruefer.value[fachprueferId] = result?.['hydra:totalItems'] || 0
        })
    }

    const getAnzahlFachbetriebeByFachprueferId = (fachprueferId: string) => {
        return anzahlFachbetriebeOfFachpruefer.value[fachprueferId]
    }

    const getFachbetriebById = (id: string): Fachbetrieb | null => {
        const fachbetrieb = fachbetriebe.value.find((item) => item.fachbetriebId === id)
        return fachbetrieb || null
    }

    const getFachbetriebByIri = (iri: string): Fachbetrieb | null => {
        const fachbetrieb = fachbetriebe.value.find((item) => item['@id'] === iri)
        return fachbetrieb || null
    }

    const fetchFachbetriebById = async (id: string) => {
        await setLoading('fetchFachbetriebById', async () => {
            addOrReplaceFachbetrieb(await service.getFachbetriebById(id))
        })
    }

    const aendereBemerkungenHauptstelle = async (request: AendereBemerkungenHauptstelleRequest) => {
        await setLoading('aendereBemerkungenHauptstelle', async () => {
            addOrReplaceFachbetrieb(await service.aendereBemerkungenHauptstelle(request))
        })
    }

    const aendereBemerkungenLandesstelle = async (
        request: AendereBemerkungenLandesstelleRequest
    ) => {
        await setLoading('aendereBemerkungenLandesstelle', async () => {
            addOrReplaceFachbetrieb(await service.aendereBemerkungenLandesstelle(request))
        })
    }

    const addOrReplaceFachbetrieb = (fachbetrieb: Fachbetrieb): void => {
        const index = fachbetriebe.value.findIndex(
            (item) => item.fachbetriebId === fachbetrieb.fachbetriebId
        )

        if (index !== -1) {
            fachbetriebe.value[index] = fachbetrieb
        } else {
            fachbetriebe.value.push(fachbetrieb)
        }
    }

    return {
        fachbetriebe,
        loadingMethods,
        isLoading,
        isMethodLoading,
        fetchFachbetriebe,
        fetchAnzahlFachbetriebeByFachprueferId,
        fetchFachbetriebById,
        getFachbetriebById,
        getFachbetriebByIri,
        getAnzahlFachbetriebeByFachprueferId,
        aendereBemerkungenHauptstelle,
        aendereBemerkungenLandesstelle
    }
})
