<script setup lang="ts">
    import { ref } from 'vue'
    import { type FieldContext, useField, useForm } from 'vee-validate'
    import { useAlertStore, useAuthStore } from '@/stores'
    import router from '@/router'
    import { FetchError } from 'ofetch'

    type LoginForm = {
        username: string
        password: string
    }

    const { handleSubmit } = useForm<LoginForm>()
    const authStore = useAuthStore()
    const alertStore = useAlertStore()

    const fields: {
        [Property in keyof LoginForm]: FieldContext<LoginForm[Property]>
    } = {
        username: useField(
            'username',
            (value) => !!value || 'Benutzername muss ausgefüllt werden.'
        ),
        password: useField('password', (value) => !!value || 'Passwort muss ausgefüllt werden.')
    }
    const showPassword = ref(false)

    const submit = handleSubmit(async (values) => {
        try {
            await authStore.login(values)
            await router.push({ name: 'users' })
        } catch (e: unknown) {
            if (e instanceof FetchError) {
                alertStore.error(e.data.message)
            } else {
                alertStore.error('Da ist wohl etwas schief gelaufen.')
            }
        }
    })
</script>

<template>
    <v-row no-gutters>
        <v-col
            cols="12"
            sm="8"
            md="6"
            lg="6"
            xl="4"
            offset-sm="2"
            offset-md="3"
            offset-lg="3"
            offset-xl="4"
            class="mt-4"
        >
            <h1 class="text-center">Vera</h1>
            <v-form @submit.prevent="submit">
                <v-row no-gutters>
                    <v-col cols="12" sm="12">
                        <v-text-field
                            class="ma-2"
                            v-model="fields.username.value.value"
                            label="Benutzername"
                            name="username"
                            :error-messages="fields.username.errorMessage.value"
                            autocomplete="username"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="12">
                        <v-text-field
                            class="ma-2"
                            v-model="fields.password.value.value"
                            label="Passwort"
                            :type="showPassword ? 'text' : 'password'"
                            name="password"
                            :error-messages="fields.password.errorMessage.value"
                            :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append-inner="showPassword = !showPassword"
                        />
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col cols="12" sm="12">
                        <v-btn
                            type="submit"
                            color="#00527e"
                            class="ma-2"
                            :loading="authStore.isLoading"
                        >
                            Anmelden
                        </v-btn>
                        <v-btn
                            size="x-small"
                            color="#00527e"
                            variant="plain"
                            :to="{ name: 'passwordReset' }"
                            >Passwort vergessen oder noch kein Passwort?</v-btn
                        >
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
</template>

<style scoped>
    .required label::after {
        content: '*';
    }
</style>
