import type { RollenName, User } from '@/js/model'
import router from '@/router'
import { useLoginService, useUserService } from '@/services'
import { determineRoles } from '@/utils'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useLoading } from '@/composables/useLoading'

const TOKEN_STORAGE_KEY = 'vera-token' as const
const USER_IRI_STORAGE_KEY = 'vera-user-iri' as const
const ORIGIN_USER_IRI_STORAGE_KEY = 'vera-user-origin-iri' as const

export const useAuthStore = defineStore('auth', () => {
    const loginService = useLoginService()
    const userService = useUserService()

    const token = useLocalStorage(TOKEN_STORAGE_KEY, '')
    const currentUserIri = useLocalStorage(USER_IRI_STORAGE_KEY, '')
    const originUserIri = useLocalStorage(ORIGIN_USER_IRI_STORAGE_KEY, '')
    const currentUser = ref<User | null>(null)
    const isAuthenticated = computed(() => !!token.value && !!currentUserIri.value)
    const currentUserRoles = computed(() => determineRoles(currentUser.value?.rollen))
    const isUserSwitched = computed(() => !!originUserIri.value)

    const hasRole = (role: RollenName): boolean => currentUserRoles.value.includes(role)

    const { isLoading, isMethodLoading, loadingMethods, setLoading } = useLoading()

    const login = async (body: { username: string; password: string }) => {
        await setLoading('login', async () => {
            const result = await loginService.login(body)

            token.value = result.token
            currentUserIri.value = result['@id']

            await fetchCurrentUser()
        })
    }

    const register = async () => {
        await setLoading('register', async () => {
            // TODO: Implement register method when the /register API exists
        })
    }

    const logout = () => {
        setLoading('logout', async () => {
            token.value = null
            currentUserIri.value = null
            originUserIri.value = null

            isUserSwitched.value ? exitUserSwitch() : window.location.reload()
        })
    }

    const fetchCurrentUser = async (): Promise<void> => {
        await setLoading('fetchCurrentUser', async () => {
            currentUser.value = await userService.getUserByIri(currentUserIri.value)
        })
    }

    const switchUser = (newUser: User): void => {
        setLoading('switchUser', async () => {
            originUserIri.value = currentUserIri.value
            currentUserIri.value = newUser['@id'] as string

            router.go(0)
        })
    }

    const exitUserSwitch = (): void => {
        setLoading('exitUserSwitch', async () => {
            currentUserIri.value = originUserIri.value
            originUserIri.value = null

            router.go(0)
        })
    }

    return {
        loadingMethods,
        isMethodLoading,
        currentUser,
        currentUserRoles,
        exitUserSwitch,
        fetchCurrentUser,
        hasRole,
        isAuthenticated,
        isLoading,
        isUserSwitched,
        login,
        logout,
        register,
        switchUser,
        token
    }
})
