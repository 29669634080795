import { useFetchItems } from '@/composables'
import type {
    AendereBemerkungenHauptstelleRequest,
    AendereBemerkungenLandesstelleRequest,
    Fachbetrieb,
    FachbetriebeResponse
} from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useFachbetriebService = () => {
    const fetch = useFetch({ baseURL: '/api/fachbetriebe' })

    const getFachbetriebById = async (id: string): Promise<Fachbetrieb> => {
        return await fetch.get<Fachbetrieb>({
            url: `/api/fachbetriebe/${id}`
        })
    }

    const getAllFachbetriebe = (fetchParams: FetchParams = {}): Promise<FachbetriebeResponse> => {
        const fetch = useFetchItems({ baseURL: '/api/fachbetriebe' })

        return fetch.get<FachbetriebeResponse>({ fetchParams })
    }

    const getAnzahlFachbetriebeByFachprueferId = async (fachprueferId: string) => {
        const fetchParams: FetchParams = {
            itemsPerPage: 0,
            fachprueferId
        }
        return await fetch.get<FachbetriebeResponse>({ fetchParams })
    }

    const aendereBemerkungenHauptstelle = async (request: AendereBemerkungenHauptstelleRequest) => {
        return await useFetch({
            baseURL: '/api/fachbetriebe/aendere-bemerkungen-hauptstelle'
        }).post<Fachbetrieb>({ body: request })
    }

    const aendereBemerkungenLandesstelle = async (
        request: AendereBemerkungenLandesstelleRequest
    ) => {
        return await useFetch({
            baseURL: '/api/fachbetriebe/aendere-bemerkungen-landesstelle'
        }).post<Fachbetrieb>({ body: request })
    }

    return {
        getFachbetriebById,
        getAllFachbetriebe,
        getAnzahlFachbetriebeByFachprueferId,
        aendereBemerkungenHauptstelle,
        aendereBemerkungenLandesstelle
    }
}
