import type { FieldContext } from 'vee-validate'

export type HydraViolation = {
    propertyPath: string
    message: string
}

export type HydraError = {
    '@type': 'hydra:Error' | 'ConstraintViolationList'
    'hydra:description': string
    'hydra:title': string
    status: number
    violations?: HydraViolation[]
}

export const extractHydraDescription = (payload: HydraError) => payload['hydra:description']

export const mapViolationsToFields = (
    fields: Record<string, FieldContext>,
    payload: HydraError
) => {
    payload.violations?.forEach((violation) => {
        fields[violation.propertyPath].setErrors(violation.message)
    })
}

export function useHydraViolations(
    fieldNameMapping: Record<string, string>,
    payload: HydraError
): Array<string> {
    const messages: string[] = []
    payload.violations?.forEach((violation) => {
        const fieldName = fieldNameMapping[violation.propertyPath]
        messages.push((fieldName ? fieldName + ': ' : '') + violation.message)
    })
    return messages
}
