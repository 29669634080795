<script setup lang="ts">
    import { type Schulung } from '@/js/model'
    import { formatDate } from '@/utils'
    import dayjs from 'dayjs'
    import { computed } from 'vue'

    const props = defineProps<{
        schulung: Schulung
        referenceDate: string | null
    }>()

    const isAktuelleSchulung = computed<boolean>(() => {
        const currentDate = props.referenceDate ? dayjs(props.referenceDate) : dayjs()
        const twoYearsAgo = currentDate.subtract(2, 'years')
        const schulungAt = dayjs(props.schulung.schulungAt)
        return schulungAt.isAfter(twoYearsAgo) && schulungAt.isBefore(currentDate)
    })
</script>

<template>
    <v-chip
        v-if="schulung.isBestanden || schulung.isWiederholungsschulung"
        :color="isAktuelleSchulung ? 'success' : 'gray'"
        prepend-icon="mdi-check-circle-outline"
        size="small"
        :title="`${isAktuelleSchulung ? 'Für die Betriebsprüfung gültige Schulung.' : 'Für die Betriebsprüfung nicht relevante Schulung.'}`"
    >
        {{ schulung.ortDerSchulung }} am {{ formatDate(schulung.schulungAt) }}
        <v-icon
            v-if="schulung.isWiederholungsschulung"
            color="secondary"
            variant="flat"
            title="Wiederholungsschulung"
            data-test="icon-wiederholungsschulung"
            class="ml-1"
            icon="mdi-repeat-variant"
        />
        <v-icon
            v-else
            color="secondary"
            variant="flat"
            title="Fachbetriebsschulung"
            data-test="icon-fachbetriebsschulung"
            class="ml-1"
            icon="mdi-email-seal"
        />
        <v-icon
            v-if="schulung.isGlykol"
            color="secondary"
            variant="flat"
            title="Erdwärme/Glykol"
            data-test="icon-glykol"
            class="ml-1"
            icon="mdi-thermometer-lines"
        />
    </v-chip>
    <v-chip
        v-else
        color="warning"
        prepend-icon="mdi-close"
        :title="`Nicht bestandene Fachbetriebsschulung.`"
    >
        {{ schulung.ortDerSchulung }} am {{ formatDate(schulung.schulungAt) }}
        <!-- this branch has only non-repeating trainings -->
        <v-icon
            color="secondary"
            variant="flat"
            title="Fachbetriebsschulung"
            data-test="icon-fachbetriebsschulung"
            class="ml-1"
            icon="mdi-email-seal"
        />
        <v-icon
            v-if="schulung.isGlykol"
            color="secondary"
            variant="flat"
            title="Erdwärme/Glykol"
            data-test="icon-glykol"
            class="ml-1"
            icon="mdi-thermometer-lines"
        />
    </v-chip>
</template>

<style scoped></style>
