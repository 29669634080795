<script setup lang="ts">
    import {
        type AendereKontaktOfBetriebsbeauftragterRequest,
        type Betriebsbeauftragter,
        type Betriebspruefung
    } from '@/js/model'
    import { useBetriebsbeauftragterService } from '@/services'
    import { computed, onMounted, ref, watch } from 'vue'
    import { useAuthStore, useBetriebsbeauftragterStore } from '@/stores'
    import type { Kontakt } from '@/js/models'
    import { HasBestandeneSchulungCell, SchulungCell } from '@/components'
    import { FetchError } from 'ofetch'
    import { useHydraViolations } from '@/utils'

    const authStore = useAuthStore()

    const props = defineProps<{
        betriebsbeauftragterId: Betriebsbeauftragter['betriebsbeauftragterId']
        betriebspruefung: Betriebspruefung
        displaySchulungGueltigkeit: boolean
    }>()

    const emit = defineEmits<{
        betriebsbeauftragterChanged: [event: string]
    }>()

    const betriebsbeauftragterStore = useBetriebsbeauftragterStore()
    const betriebsbeauftragterService = useBetriebsbeauftragterService()

    const dialog = ref<boolean>(false)
    const isRequestSend = ref<boolean>(false)
    const globalError = ref<string[]>([])
    const kontakt = ref<Kontakt>({
        telefon: [],
        mobil: [],
        telefax: [],
        email: [],
        internet: '',
        sonstiges: []
    })

    const isAllowedToAccessKontakt = computed(() => {
        return betriebsbeauftragter.value && authStore.hasRole('Fachpruefer')
    })
    const isSubmitDisabled = computed(() => {
        if (!betriebsbeauftragter.value || !authStore.hasRole('Fachpruefer')) {
            return true
        }
        return (
            kontakt.value.email[0] === betriebsbeauftragter.value.kontakt.email[0] &&
            kontakt.value.mobil[0] === betriebsbeauftragter.value.kontakt.mobil[0] &&
            kontakt.value.telefon[0] === betriebsbeauftragter.value.kontakt.telefon[0] &&
            kontakt.value.sonstiges[0] === betriebsbeauftragter.value.kontakt.sonstiges[0]
        )
    })
    const betriebsbeauftragter = computed(() => {
        return betriebsbeauftragterStore.getBetriebsbeauftragterById(props.betriebsbeauftragterId)
    })

    function setInitialValues() {
        if (betriebsbeauftragter.value && betriebsbeauftragter.value.kontakt) {
            kontakt.value = JSON.parse(JSON.stringify(betriebsbeauftragter.value.kontakt))
        }
        isRequestSend.value = false
        globalError.value = []
    }

    onMounted(async () => {
        await betriebsbeauftragterStore.fetchBetriebsbeauftragterById(props.betriebsbeauftragterId)
        setInitialValues()
    })
    watch([dialog], async () => {
        // ensure dialog is updated when other kontakt is changed in another dialog with same kontakt
        await betriebsbeauftragterStore.fetchBetriebsbeauftragterById(props.betriebsbeauftragterId)
        setInitialValues()
    })

    const onEmailInput = () => {
        if ('' === kontakt.value.email[0]) {
            kontakt.value.email = []
        }
    }

    const handleChangeKontaktSubmit = async () => {
        globalError.value = []
        isRequestSend.value = true
        const request: AendereKontaktOfBetriebsbeauftragterRequest = {
            betriebsbeauftragter: `/api/betriebsbeauftragte/${props.betriebsbeauftragterId}`,
            kontakt: kontakt.value
        }

        // allow to remove contact details
        if ('' === request.kontakt?.email[0]) {
            request.kontakt.email = []
        }
        if ('' === request.kontakt?.mobil[0]) {
            request.kontakt.mobil = []
        }
        if ('' === request.kontakt?.telefon[0]) {
            request.kontakt.telefon = []
        }
        if ('' === request.kontakt?.sonstiges[0]) {
            request.kontakt.sonstiges = []
        }

        try {
            const result =
                await betriebsbeauftragterService.aendereKontaktOfBetriebsbeauftragter(request)

            if (result) {
                emit(
                    'betriebsbeauftragterChanged',
                    `/api/betriebsbeauftragte/${props.betriebsbeauftragterId}`
                )
                await handleDismiss()
            }
        } catch (e: unknown) {
            if (e instanceof FetchError) {
                globalError.value = useHydraViolations(
                    {
                        'emails[0]': 'Email'
                    },
                    e.data
                )
            }
            isRequestSend.value = false
        }
    }

    const handleDismiss = async () => {
        dialog.value = false
        setInitialValues()
    }
</script>

<template>
    <v-dialog
        v-if="betriebsbeauftragter"
        v-model="dialog"
        max-width="600"
        @after-leave="handleDismiss"
    >
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                v-bind="activatorProps"
                :append-icon="isAllowedToAccessKontakt ? 'mdi-pencil' : 'mdi-eye'"
                variant="text"
                class="text-none"
                data-test="action-betriebsbeauftragter-edit"
            >
                {{ betriebsbeauftragter.fullName }}
            </v-btn>
            <has-bestandene-schulung-cell
                :schulungen="betriebsbeauftragter.schulungen"
                :betriebspruefung="betriebspruefung"
                :display-gueltigkeit="displaySchulungGueltigkeit"
            />
        </template>
        <form autocomplete="off">
            <v-card
                prepend-icon="mdi-calendar"
                title="Kontaktdaten des Betriebsbeauftragten ändern"
            >
                <v-card-title>{{ betriebsbeauftragter.fullName }}</v-card-title>
                <v-card-text v-if="isAllowedToAccessKontakt">
                    <v-row dense>
                        <v-col cols="12" md="12" sm="12"
                            ><v-text-field
                                v-model="kontakt.email[0]"
                                type="email"
                                placeholder="vorname.nachname@example.de"
                                label="E-Mail"
                                name="email"
                                @input="onEmailInput"
                            /><v-text-field
                                v-model="kontakt.mobil[0]"
                                type="tel"
                                placeholder="0175 123 345 6789"
                                label="Mobil"
                                name="mobile"
                            />
                            <v-text-field
                                v-model="kontakt.telefon[0]"
                                type="tel"
                                placeholder="0123 45 67 890"
                                label="Festnetz"
                                name="phone"
                            />
                            <v-text-field
                                v-model="kontakt.sonstiges[0]"
                                placeholder="z. B. Messenger-ID"
                                label="Sonstiges"
                                name="other"
                            />
                        </v-col>
                    </v-row>
                    <v-alert
                        v-for="error in globalError"
                        :key="error"
                        class="mx-auto mt-2 mb-2"
                        elevation="16"
                        max-width="600"
                        type="error"
                        :text="error"
                    />

                    <v-alert type="info" color="grey" icon="mdi-eye">
                        Die Kontaktdaten des Betriebsbeauftragten sind nur für Sie als Fachprüfer
                        einsehbar.
                    </v-alert>
                </v-card-text>
                <v-divider v-if="isAllowedToAccessKontakt"></v-divider>
                <v-card-title>Schulungen</v-card-title>
                <v-card-text>
                    <v-sheet class="d-flex flex-wrap ga-1 flex-column">
                        <v-row
                            v-for="schulung in betriebsbeauftragter.schulungen.schulungen"
                            :key="schulung.schulungAt"
                        >
                            <schulung-cell
                                :schulung="schulung"
                                :reference-date="betriebspruefung.terminAm"
                            />
                        </v-row>
                    </v-sheet>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        v-if="isAllowedToAccessKontakt"
                        text="Abbrechen"
                        variant="plain"
                        @click="handleDismiss"
                    ></v-btn>

                    <v-btn
                        v-if="isAllowedToAccessKontakt"
                        color="primary"
                        text="Speichern"
                        variant="tonal"
                        :disabled="isSubmitDisabled || isRequestSend"
                        @click="handleChangeKontaktSubmit"
                        data-test="action-betriebsbeauftragter-save"
                    />
                    <v-btn
                        v-if="!isAllowedToAccessKontakt"
                        color="primary"
                        text="Schließen"
                        variant="tonal"
                        @click="handleDismiss"
                        data-test="action-close"
                    />
                </v-card-actions>
            </v-card>
        </form>
    </v-dialog>
</template>

<style scoped></style>
