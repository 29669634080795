<script setup lang="ts">
    import { type Betriebspruefung, type Schulungen } from '@/js/model'

    import { computed } from 'vue'
    import dayjs from 'dayjs'
    import { formatDate } from '@/utils'

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
        schulungen: Schulungen
        displayGueltigkeit: boolean
    }>()

    const letzteErfolgreicheSchulungAm = computed(() => {
        return props.betriebspruefung.betriebsbeauftragterSchulungStatus
            .letzteErfolgreicheSchulungAm
    })

    const letzteSchulungAm = computed(() => {
        return props.betriebspruefung.betriebsbeauftragterSchulungStatus.letzteSchulungAm
    })

    const success = computed(() => {
        return ['FBS_GUELTIG', 'WS_GUELTIG'].includes(
            props.betriebspruefung.betriebsbeauftragterSchulungStatus.status
        )
    })

    const schulungText = computed(() => {
        if (letzteErfolgreicheSchulungAm.value) {
            if (props.displayGueltigkeit) {
                return (
                    '(gültig bis ' +
                    formatDate(
                        dayjs(letzteErfolgreicheSchulungAm.value)
                            .add(2, 'year')
                            .subtract(1, 'day')
                            .toISOString()
                    ) +
                    ')'
                )
            } else {
                return '(Prüfung am ' + formatDate(letzteErfolgreicheSchulungAm.value) + ')'
            }
        } else if (letzteSchulungAm.value) {
            return '(Versuch am ' + formatDate(letzteSchulungAm.value) + ')'
        } else {
            return ''
        }
    })
</script>

<template>
    <span class="text-no-wrap chip-spacing">
        <v-chip
            :color="success ? 'success' : 'error'"
            :prepend-icon="success ? 'mdi-check-circle-outline' : 'mdi-close'"
        >
            <span
                v-if="
                    props.betriebspruefung.betriebsbeauftragterSchulungStatus.status ===
                    'FBS_NOTWENDIG'
                "
                title="Zum Zeitpunkt der Prüfung fehlt eine gültige Fachbetriebsschulung."
            >
                FBS notwendig
            </span>
            <span
                v-else-if="
                    props.betriebspruefung.betriebsbeauftragterSchulungStatus.status ===
                    'FBS_GUELTIG'
                "
                title="Zum Zeitpunkt der Prüfung gibt es eine gültige Fachbetriebsschulung."
            >
                FBS gültig
            </span>
            <span
                v-else-if="
                    props.betriebspruefung.betriebsbeauftragterSchulungStatus.status ===
                    'FBS_ABGELAUFEN'
                "
                title="Zum Zeitpunkt der Prüfung ist die letzte Schulung abgelaufen."
            >
                FBS abgelaufen
            </span>
            <span
                v-else-if="
                    props.betriebspruefung.betriebsbeauftragterSchulungStatus.status ===
                    'WS_GUELTIG'
                "
                title="Zum Zeitpunkt der Prüfung gibt es eine gültige Wiederholungsschulung."
            >
                WS gültig
            </span>
            <span
                v-else-if="
                    props.betriebspruefung.betriebsbeauftragterSchulungStatus.status ===
                    'WS_ABGELAUFEN'
                "
                title="Zum Zeitpunkt der Prüfung ist die letzte Schulung abgelaufen."
            >
                WS abgelaufen
            </span>
            <span>&nbsp;{{ schulungText }}</span>
            <v-icon
                v-if="props.betriebspruefung.betriebsbeauftragterSchulungStatus.glykol"
                title="Erdwärme/Glykol"
                class="ml-1"
            >
                mdi-thermometer-lines
            </v-icon>
        </v-chip>
    </span>
</template>

<style scoped>
    .chip-spacing {
        margin-top: 1px;
        margin-bottom: 1px;
        display: inline-block;
    }
</style>
